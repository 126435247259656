<template>
    <div class="text-left">

        <form class="col-12" @submit.prevent="topUpWallet">
            <BaseLoading style="min-height: 100px;" v-if="paymentForm.busy"></BaseLoading>

            <div v-else>

                

                <div class="form-group">
                    <label for="inputEmail3" class="col-form-label text-left text-dark">Writer</label>
                    <div class="">
                        <select  v-model="paymentForm.reference" class="form-control" id="inputEmail4" placeholder="Select Writer" required="required" @change="_getWriterOrders">
                            <option value="">Select Writer</option>
                            <option v-for="(writer, index) in writers" :key="index + 'wrts'" :value="writer.id">
                                {{ writer.first_name || "" }} (#{{ writer.id || "" }}) 
                            </option>
                        </select>
                        
                      
                        
                    </div>
                </div>
                <div class="form-group" v-if="loading_orders">
                    <BaseLoading style="min-height: 60px;height: 60px;"></BaseLoading>
                </div>
                <div class="form-group" v-else>
                    <label for="inputEmail3" class="col-form-label text-left text-dark">Order</label>
                    <div class="">
                

                        <!-- <select  v-model="order_id" class="form-control" id="inputEmail4" placeholder="Select Writer" required="required">
                            <option value="">Select Order   </option>
                            <option v-for="(order, index) in writerOrders" :key="index + 'wrts'" :value="order">
                                #{{ order || "" }}
                            </option>
                        </select> -->
                        <v-select
                            label="order"
                            v-model="order_id"
                            placeholder="Start typing to search"
                            :options="writerOrders"
                            :reduce="(order) => order"
                        ></v-select>

                        
                    </div>
                </div>

                <div class="form-group">
                    <label for="inputEmail3" class="col-form-label text-left text-dark">Enter Amount</label>
                    <div class="">
                        <input  v-model="paymentForm.amount" class="form-control" id="inputEmail3" placeholder="Enter amount" required="required">
                    </div>
                </div>


                <input type="hidden" value="tip" v-model="paymentForm.type">
            </div>
            <div class="modal-footer ">
            <button :disabled="paymentForm.busy" class="btn btn-link-primary " type="submit">
                Tip Writer
                 <!-- <img width="100" src="../../../assets/global_assets/images/payment.png" alt="Credit and debit card"> -->
            </button>
            <button type="button" class="btn btn-link " data-dismiss="modal">Close</button>

            </div>
            
        </form>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    data: () => ({
        loading: false,
        order_id: '',
        loading_orders: ''
    }),
    computed: {
        ...mapState("payment", ["paymentForm", "payment_vars"]),
        ...mapState("auth", ["user"]),
        ...mapState("writers", ["writers", "writerOrders"]),
        ...mapState("orders", ["orders"]),
    },
    mounted() {
        this.paymentForm.busy = true
        this._getOrders()
        this.getWriters()
            .then(res => console.log(res))
            .catch(err => console.log(err))
            .finally(() => this.paymentForm.busy = false)
    },
    methods: {
        ...mapActions('payment', ["_getPaymentUrl", "_getTipEntries"]),
        ...mapActions('writers', ["getWriters", "getWriterOrders"]),
        ...mapActions('orders', ["_getOrders"]),
        _getWriterOrders() {
            console.log("this.paymentForm.reference", this.paymentForm.reference);
            this.loading_orders = true
            this.getWriterOrders(this.paymentForm.reference)
                .then((res) => console.log(res))
                .catch((err) => console.log(err))
                .finally(() => this.loading_orders = false)
        },
        topUpWallet(){
            this.paymentForm.type = "tip"
            this.paymentForm.reference = `${this.paymentForm.reference}_${this.order_id}` // writerid_orderid
            this.paymentForm.call_back = document.location.href

            console.log("payment_form", this.paymentForm);
            if (this.paymentForm.amount && this.paymentForm.amount > 0 && this.paymentForm.reference) {
                
                this.loading = true;
                this._getPaymentUrl(this.paymentForm)
                    .then((res) => {
                        console.log(res);
                        if (res && res.data.Data && res.data.Data.checkout) {
                            document.location.href = res.data.Data.checkout
                        } else {
                            if (!res.data.Message) {
                                
                                this.$notify({
                                    title: "Success",
                                    text: "Writer tipped successifully.",
                                    style: "success",
                                });
                            } else {
                                this.$notify({
                                    title: "Error",
                                    text: res.data.Message || "Error while tipping writer from wallet.",
                                    style: "danger",
                                });
                            }
                        }
                    })
                    .catch((err) => {
                        // display err
                        console.log(err);
                        this.$notify({
                            title: "Error",
                            text: err.response.data ? err.response.data.Message : "Error",
                            style: "danger",
                        });
                        console.log(err);
                    })
                    .finally(() => {
                        this._getTipEntries();
                        this.loading = false
                    })
            } else {
                this.$notify({
                        title: "Error",
                        text: "Please enter topup amount and select a writer",
                        style: "danger",
                    });
            }
        }
    }
    
}
</script>